import {
  Box,
  Button,
  ButtonGroup,
  ButtonSize,
  ButtonVariant,
  Color,
  ColorPreset,
  FontWeight,
  Glyph,
  HoverEffect,
  Icon,
  Link,
  PlainButton,
  Space,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { LinkBuilder } from "src/components/routing";
import {
  OptimizelyExperiment,
  OptimizelyVariation,
} from "@optimizely/react-sdk";
import { useLocalStorage } from "src/common/hooks/useLocalStorage/useLocalStorage";
import { useUser } from "src/queries/user";
import { Route, routerPush } from "src/common/routing";
import { Trans } from "@lingui/macro";
import ActivationDiscountCountdown from "src/components/routes/Setup/common/components/ActivationDiscount/ActivationDiscountCountdown";
import { MerchantOnboardingSetupEvents } from "src/components/routes/Setup/common/constants/MerchantOnboardingSetupEvents";
import { SetupPaymentsEnabled } from "src/components/routes/SetupPayments/SetupPaymentsEnabled";
import {
  OptimizelyFlag,
  OptimizelyVariant,
} from "src/components/third-parties/Optimizely/constants";

import { Partner } from "../types";
import Card from "../Card";
import { useXeroOpenUrl } from "../useXeroOpenUrl";
import { useSegmentForSetup } from "../../common/hooks/useSegmentForSetup";
import { SIDEBAR_STEPS_BUTTON_ID } from "../../common/components/Header";

interface AccountVerifiedProps {
  partner?: Partner;
}

interface ReturnToPartnerButtonProps
  extends Pick<AccountVerifiedProps, "partner"> {}

const ReturnToPartnerButton: React.FC<ReturnToPartnerButtonProps> = ({
  partner,
}) => {
  const { sendEvent } = useSegmentForSetup();
  const xeroOpenUrl = useXeroOpenUrl();
  const partnerName = partner?.name;

  return (
    <OptimizelyExperiment
      experiment={OptimizelyFlag.TURBO_XERO_PARTNER_EXPERIENCE_THE_VALUE}
    >
      <OptimizelyVariation variation={OptimizelyVariant.FLAG_ON}>
        <Link
          href={xeroOpenUrl}
          target="_blank"
          variant={ButtonVariant.PrimaryOnLight}
          onClick={() => {
            sendEvent(
              MerchantOnboardingSetupEvents.VerificationNextStepsCtaClicked,
              { partner_name: "Xero", target: "complete_setup" }
            );
          }}
        >
          Complete setup
        </Link>
      </OptimizelyVariation>
      <OptimizelyVariation default>
        <LinkBuilder absolute={true} route={Route.Home}>
          {({ href }) => (
            <Link
              variant={ButtonVariant.PrimaryOnLight}
              size={ButtonSize.Lg}
              href={partner?.url ?? href}
            >
              {partnerName ? (
                <Trans id="setup.account-status.status-cards.return-to-partner-action">
                  Return to {partnerName}
                </Trans>
              ) : (
                <Trans id="setup.account-status.status-cards.return-to-dashboard-action">
                  Return to Dashboard
                </Trans>
              )}
            </Link>
          )}
        </LinkBuilder>
      </OptimizelyVariation>
    </OptimizelyExperiment>
  );
};

const AccountVerified: React.FC<AccountVerifiedProps> = ({ partner }) => {
  const user = useUser();
  const [accountVerifiedStart, setAccountVerifiedStart] = useLocalStorage(
    `gc.account-verified-start.${user?.id}`,
    {
      clicked: false,
    }
  );
  const { sendEvent } = useSegmentForSetup();
  const xeroOpenUrl = useXeroOpenUrl();

  return (
    <Card
      header={{
        content: (
          <Box>
            <Text preset={TypePreset.Heading_03}>
              <Trans id="setup.account-status.status-cards.account-is-verified">
                Your account is verified
              </Trans>
            </Text>
          </Box>
        ),
        bg: Color.Success_50_A25,
        icon: (
          <Icon name={Glyph.TickCircle} color={Color.Success_300} size="32px" />
        ),
      }}
      body={
        <Box>
          <ActivationDiscountCountdown />

          <Text preset={TypePreset.Body_02} color={ColorPreset.TextOnLight_02}>
            You can now get paid securely with GoCardless. We&apos;ll pay out
            all funds you collect directly to your bank account.
          </Text>

          <Space v={2} />

          <ButtonGroup
            arrangement={["column-center", null, "row-start"]}
            spacing={2}
          >
            {partner ? (
              <ReturnToPartnerButton partner={partner} />
            ) : (
              <OptimizelyExperiment
                experiment={
                  OptimizelyFlag.TURBO_XERO_PARTNER_EXPERIENCE_THE_VALUE
                }
              >
                <OptimizelyVariation variation={OptimizelyVariant.FLAG_ON}>
                  <Link
                    href={xeroOpenUrl}
                    target="_blank"
                    variant={ButtonVariant.PrimaryOnLight}
                    onClick={() => {
                      sendEvent(
                        MerchantOnboardingSetupEvents.VerificationNextStepsCtaClicked,
                        { partner_name: "Xero", target: "complete_setup" }
                      );
                    }}
                  >
                    Complete setup up in Xero
                  </Link>
                </OptimizelyVariation>
                <OptimizelyVariation default>
                  <SetupPaymentsEnabled
                    defaultNode={
                      <Button
                        variant={ButtonVariant.PrimaryOnLight}
                        size={ButtonSize.Lg}
                        onClick={() => {
                          routerPush({
                            route: accountVerifiedStart.clicked
                              ? Route.Home
                              : Route.WelcomeUsageSelection,
                          });
                          setAccountVerifiedStart({ clicked: true });
                        }}
                      >
                        <Trans id="setup.account-status.status-cards.start-using-gocardless">
                          Start using GoCardless
                        </Trans>
                      </Button>
                    }
                  >
                    <Button
                      variant={ButtonVariant.PrimaryOnLight}
                      size={ButtonSize.Lg}
                      onClick={() => {
                        routerPush({
                          route: Route.Home,
                        });
                        setAccountVerifiedStart({ clicked: true });
                      }}
                    >
                      <Trans id="setup.account-status.status-cards.start-using-gocardless">
                        Start using GoCardless
                      </Trans>
                    </Button>
                  </SetupPaymentsEnabled>
                </OptimizelyVariation>
              </OptimizelyExperiment>
            )}

            <PlainButton
              effect={HoverEffect.TextDecoration}
              onClick={() => {
                document.getElementById(SIDEBAR_STEPS_BUTTON_ID)?.click();
              }}
            >
              <Text preset={TypePreset.Body_02} weight={FontWeight.SemiBold}>
                <Trans id="setup.account-status.status-cards.edit-my-details">
                  Edit my details
                </Trans>
              </Text>
            </PlainButton>
          </ButtonGroup>
        </Box>
      }
    />
  );
};

export default AccountVerified;
