import {
  RequiredActionsStatus,
  RequiredVerificationStatus,
} from "@gocardless/api/dashboard/types";
import {
  OrganisationType,
  useOrganisationWithType,
} from "src/queries/organisationType";

import { OptimisedVerificationStatus } from "../optimised-verification-status";

import VerificationStatus from "./VerificationStatus";
import { useAccountStatus } from "./useAccountStatus";

export const Statuses = {
  Successful: [RequiredActionsStatus.Successful],
  Unsuccessful: [
    RequiredActionsStatus.Unsuccessful,
    RequiredActionsStatus.CouldNotVerify,
  ],
  Pending: [
    RequiredActionsStatus.Pending,
    RequiredActionsStatus.PendingResponse,
    RequiredVerificationStatus.Pending,
  ],
  InReview: [
    RequiredActionsStatus.InReview,
    RequiredActionsStatus.Processing,
    RequiredActionsStatus.InAutomaticReview,
    RequiredVerificationStatus.InReview,
  ],
};

interface AccountStatusProps {
  inRedesignVerificationStatusAndFileUploadExperiment?: boolean;
}

const AccountStatus: React.FC<AccountStatusProps> = ({
  inRedesignVerificationStatusAndFileUploadExperiment = false,
}) => {
  const { status, partner } = useAccountStatus();
  const { organisationType } = useOrganisationWithType();
  const isPaymentProvider =
    organisationType === OrganisationType.PaymentProvider;

  return inRedesignVerificationStatusAndFileUploadExperiment ? (
    <OptimisedVerificationStatus status={status} partner={partner} />
  ) : (
    <VerificationStatus
      status={status}
      partner={partner}
      isPaymentProvider={isPaymentProvider}
    />
  );
};

export default AccountStatus;
