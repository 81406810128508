export enum MerchantOnboardingSetupEvents {
  PackageSelectionSelected = "Merchant Onboarding - Package Selection: Package selected",
  PackageSelectionCompleted = "Merchant Onboarding - Package Selection: Step completed",
  BusinessInformationStarted = "Merchant Onboarding - Business Information: Step started",
  BusinessInformationCompleted = "Merchant Onboarding - Business Information: Step completed",
  BusinessInformationEditClicked = "Merchant Onboarding - Business Information: Edit clicked",
  BusinessInformationDetailsEdited = "Merchant Onboarding - Business Information: Details edited",
  BusinessInformationManuallyEnterDetailsSelected = "Merchant Onboarding - Business Information: Manually enter details selected",
  BusinessInformationExistingCompanySelected = "Merchant Onboarding - Business Information: Existing company selected",
  AboutYouStarted = "Merchant Onboarding - About You: Step started",
  AboutYouCompleted = "Merchant Onboarding - About You: Step completed",
  BusinessContactDetailsStarted = "Merchant Onboarding - Business Contact Details: Step started",
  BusinessContactDetailsDeveloperDetailsAdded = "Merchant Onboarding - Business Contact Details: Developer details added",
  BusinessContactDetailsCompleted = "Merchant Onboarding - Business Contact Details: Step completed",
  BusinessCategoryStarted = "Merchant Onboarding - Business Category: Step started",
  BusinessCategoryCompleted = "Merchant Onboarding - Business Category: Step completed",
  BusinessDirectorStarted = "Merchant Onboarding - Business Director: Step started",
  BusinessDirectorCompleted = "Merchant Onboarding - Business Director: Step completed",
  BusinessOwnersStarted = "Merchant Onboarding - Business Owners: Step started",
  BusinessOwnersCompleted = "Merchant Onboarding - Business Owners: Step completed",
  BankAccountSubmitted = "Merchant Onboarding - Bank Accounts: Account submitted",
  BankAccountAccountAdded = "Merchant Onboarding - Bank Accounts: Account added",
  BankAccountInternationalAccountSubmitted = "Merchant Onboarding - Bank Accounts: International account submitted",
  BankAccountInternationalAccountAdded = "Merchant Onboarding - Bank Accounts: International account added",
  BankAccountCompleted = "Merchant Onboarding - Bank Accounts: Step completed",
  BankAccountVerificationStarted = "Merchant Onboarding - Bank Account Verification: Step started",
  BankAccountVerificationCompleted = "Merchant Onboarding - Bank Account Verification: Step completed",
  BankAccountVerificationErrorRaised = "Merchant Onboarding - Bank Account Verification: Error raised",
  BankAccountVerificationHavingTroubleLinkClicked = "Merchant Onboarding - Bank Account Verification: Having trouble link clicked",
  SuccessScreenPageViewed = "Merchant Onboarding - Success Screen: Page viewed",
  SuccessScreenReturnToDashboardCtaClicked = "Merchant Onboarding - Success Screen: Return to dashboard CTA clicked",
  SuccessScreenEditBrandCtaClicked = "Merchant Onboarding - Success Screen: Edit brand CTA clicked",
  StepsSidebarOpened = "Merchant Onboarding - Steps sidebar opened",
  ReduceFrictionFlowExited = "Merchant Onboarding - Reduce friction flow exited",
  CompanyInformationManuallySearchCategorySelected = "Merchant Onboarding - Company Information: Manually search category selected",
  CompanyInformationManuallySearchCategoryReturned = "Merchant Onboarding - Company Information: Manually search category returned",
  BillingDetailsPageViewed = "Merchant Onboarding - Billing details: Page viewed",
  BillingDetailsMandateSetupStarted = "Merchant Onboarding - Billing details: Mandate setup started",
  BillingDetailsCompleted = "Merchant Onboarding - Billing details: They have succesfully set up a Direct Debit",
  BillingDetailsEdited = "Merchant Onboarding - Billing details: They edited the billing details",
  TrustOwnersStarted = "Merchant Onboarding - Trust Owners: Step started",
  TrustOwnersCompleted = "Merchant Onboarding - Trust Owners: Step completed",

  // AIS experiment
  BankLookupPageViewed = "Verification - Bank Lookup Page Viewed",
  BankLookupFieldPopulated = "Verification - Bank Lookup Field Populated",
  BankLookupItemClicked = "Verification - Bank Lookup Item Clicked",
  ManuallyAddBankAccountClicked = "Verification - Manually Add Bank Account Clicked",
  NameMatchPageViewed = "Verification - Name Match Page Viewed",
  AisConsentPageViewed = "Verification - AIS Consent Page Viewed",
  AisConnectionMethodPageViewed = "Verification - AIS Connection Method Page Viewed",
  AisConnectionMethodSelected = "Verification - AIS Connection Method Selected",
  AisRedirected = "Verification - AIS Redirected",
  AisSuccessScreenViewed = "Verification - AIS Success Screen Viewed",
  NameMismatchClicked = "Verification - Name Mismatch Link Clicked",
  NameMismatchModalDismissed = "Verification - Name Mismatch Modal Dismissed",
  NameMismatchPageViewed = "Verification - AIS Name Mismatch Page Viewed",
  NameMismatchOptionSelected = "Verification - AIS Name Mismatch Option Selected",
  AisConsentDeniedPageViewed = "Verification - AIS Consent Denied Page Viewed",

  // New taxonomy naming convention
  PackageSelectionPageViewed = "Onboarding - Package Selection Page Viewed",
  BusinessTypeDropdownChanged = "Onboarding - Business Type Dropdown Changed",
  VerificationBankStatementNamePageViewed = "Verification - Bank Statement Name Page Viewed",
  VerificationBankStatementNameSubmitted = "Verification - Bank Statement Name Submitted",
  VerificationTrusteeDetailsPageViewed = "Verification - Trustee Details Page Viewed",
  VerificationTrusteeDetailsSubmitted = "Verification - Trustee Details Submitted",
  VerificationTrusteeChangeClicked = "Verification - Trustee Change Clicked",
  VerificationTrusteeAdded = "Verification - Trustee Added",
  VerificationTrusteeRemoved = "Verification - Trustee Removed",
  VerificationAccountHolderIsTrusteeChanged = "Verification - Account Holder Is Trustee Changed",

  // Partner Experience The Value experiment
  VerificationNextStepsExpanded = "Verification - Next Steps Expanded",
  VerificationNextStepsCtaClicked = "Verification - Next Steps CTA Clicked",
}
