import { Interpose, Space } from "@gocardless/flux-react";
import {
  OptimizelyExperiment,
  OptimizelyVariation,
} from "@optimizely/react-sdk";
import { SetupPaymentsEnabled } from "src/components/routes/SetupPayments/SetupPaymentsEnabled";
import { CreditorsVerificationStatus as VerificationStatusEnum } from "@gocardless/api/dashboard/types";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import {
  OptimizelyFlag,
  OptimizelyVariant,
} from "src/components/third-parties/Optimizely/constants";
import { useEffect } from "react";

import PageTitle from "../common/components/PageTitle";
import { useSegmentForSetup } from "../common/hooks/useSegmentForSetup";
import { MerchantOnboardingSetupEvents } from "../common/constants/MerchantOnboardingSetupEvents";
import { useCollectionsPermitted } from "../../SetupPayments/common/hooks/useCollectionsPermitted";

import AccountActionRequired from "./status-cards/AccountActionRequired";
import AccountVerified from "./status-cards/AccountVerified";
import BrandingAndCustomisation from "./status-cards/BrandingAndCustomisation";
import ReturnToPartnerOrDashboard from "./status-cards/ReturnToPartnerOrDashboard";
import VerificationInProgress from "./status-cards/VerificationInProgress";
import { Partner } from "./types";
import PartnerEtvVerificationStatus from "./PartnerEtvVerificationStatus";

export interface VerificationStatusProps {
  status: VerificationStatusEnum;
  partner?: Partner;
  isPaymentProvider?: boolean;
}

const VerificationStatus: React.FC<VerificationStatusProps> = ({
  status,
  partner,
  isPaymentProvider,
}) => {
  const { sendEvent } = useSegmentForSetup();
  const { collectionsEnabledTrackingAttribute } = useCollectionsPermitted();

  useEffect(() => {
    sendEvent(MerchantOnboardingSetupEvents.SuccessScreenPageViewed, {
      verification_status: status,
      new_design: false,
      ...collectionsEnabledTrackingAttribute,
    });
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const pageTitle = (verificationStatus: VerificationStatusEnum) => {
    switch (verificationStatus) {
      case VerificationStatusEnum.ActionRequired:
        return (
          <PageTitle
            title={i18n._(
              t({
                id: "account-status.page-title",
                message: "Your account",
              })
            )}
          />
        );
      case VerificationStatusEnum.InReview:
        return (
          <PageTitle
            title={i18n._(
              t({
                id: "setup.account-status.thanks",
                message: "Thanks for submitting your details",
              })
            )}
          />
        );
      case VerificationStatusEnum.Successful:
        return (
          <PageTitle
            title={i18n._(
              t({
                id: "account-status.page-title.your-are-all-set",
                message: "You're all set!",
              })
            )}
          />
        );
    }
  };

  const verificationCard = (verificationStatus: VerificationStatusEnum) => {
    switch (verificationStatus) {
      case VerificationStatusEnum.ActionRequired:
        return <AccountActionRequired />;
      case VerificationStatusEnum.InReview:
        return <VerificationInProgress />;
      case VerificationStatusEnum.Successful:
        return <AccountVerified partner={partner} />;
    }
  };

  const brandingAndCustomisation = (hide: boolean) => {
    if (hide) {
      return <></>;
    }
    return (
      <SetupPaymentsEnabled defaultNode={<BrandingAndCustomisation />}>
        {/* don't show branding and customisation */}
        <></>
      </SetupPaymentsEnabled>
    );
  };

  const nextSteps =
    status === VerificationStatusEnum.Successful ? (
      <></>
    ) : (
      <ReturnToPartnerOrDashboard partner={partner} />
    );

  const hideBranding = !!isPaymentProvider;

  return (
    <OptimizelyExperiment
      experiment={OptimizelyFlag.TURBO_XERO_PARTNER_EXPERIENCE_THE_VALUE}
    >
      <OptimizelyVariation variation={OptimizelyVariant.FLAG_ON}>
        <PartnerEtvVerificationStatus status={status} partner={partner} />
      </OptimizelyVariation>
      <OptimizelyVariation default>
        {pageTitle(status)}
        <Interpose node={<Space v={3} />}>
          {verificationCard(status)}
          {brandingAndCustomisation(hideBranding)}
          {nextSteps}
        </Interpose>
      </OptimizelyVariation>
    </OptimizelyExperiment>
  );
};

export default VerificationStatus;
